import React, {useContext, useEffect, useState} from 'react';
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import {Box, Button, Chip, Divider, IconButton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useLocation} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {getBasketTotalPrice} from "../../common/struct/globalVar";

function BasketHeaderStep(props: {step: number, currentStep: number, isMobile: boolean}): JSX.Element {
  const {t} = useTranslation();
  const {step, currentStep, isMobile} = props;

  const fontColor = step == currentStep ? "primary.contrastText" : "neutral.main";
  return (
    <Chip sx={{backgroundColor: step == currentStep ? "primary.main" : currentStep > step ? "transparent" : "ornament.dark",
      border: currentStep > step ? "1px solid" : "none", borderColor: currentStep > step ? "primary.main" : "inherit"}} label={
      <Box sx={{display: "flex", px: 0}}>
        <Typography variant="body2" color={fontColor}>
          {step + " - "}
        </Typography>
        {!isMobile && <Typography variant="body2" color={fontColor} sx={{fontWeight: "bold"}}>
          {t("basket.step_" + step)}
        </Typography>}
      </Box>
    }/>
  )
}

export default function BasketHeader(): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const [store] = useContext<StoreContext>(AppContext);
  const [step, setStep] = useState(1);
  const complementActivated = (store.operation?.complementActivated??false) && getBasketTotalPrice(store.basket) > (store.user?.rewardPoints??0);

  useEffect(() => {
    if (location.pathname == PUBLIC_URL.BASKET) setStep(1)
    if (location.pathname == PUBLIC_URL.BASKET_SHIPPING_ADDRESS) setStep(2)
    if (location.pathname == PUBLIC_URL.BASKET_BILLING_ADDRESS) setStep(3)
    if (location.pathname == PUBLIC_URL.BASKET_PAYMENT) setStep(4)
  }, [location])

  const goBack = (): void => {
    const targetUrl = (step === 1) ? PUBLIC_URL.HOME : PUBLIC_URL.BASKET;
    window.location.href = targetUrl;
  }

  const hPadding = (store.isMobile) ? 1 : 4;
  const vPadding = (store.isMobile) ? 0 : 3;
  const dividerColor = location.pathname.startsWith(PUBLIC_URL.BASKET_SHIPPING_ADDRESS) ? "primary.main" : "neutral.light";
  return (
    <Box sx={{display: "flex", justifyContent: "space-between", px: hPadding, py: vPadding, overflowX: "auto", width: MAX_DESKTOP_SIZE}}>
      {store.isMobile && <IconButton onClick={goBack}><ArrowBackIcon/></IconButton>}
      {!store.isMobile && <Button sx={{color: "neutral.main", fontWeight: "bold"}} variant="text" onClick={goBack} startIcon={<ArrowBackIcon/>}>
        {t(`basket.step_${step}_back`)}
      </Button>}
      <Box sx={{width: store.isMobile ? "unset" : "85%", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BasketHeaderStep step={1} currentStep={step} isMobile={store.isMobile}/>
        <Divider sx={{backgroundColor: dividerColor, borderColor: dividerColor, width: "40px", height: "1px"}}/>
        <BasketHeaderStep step={2} currentStep={step} isMobile={store.isMobile}/>
        {complementActivated && <>
          <Divider sx={{backgroundColor: dividerColor, borderColor: dividerColor, width: "40px", height: "1px"}}/>
          <BasketHeaderStep step={3} currentStep={step} isMobile={store.isMobile}/>
          <Divider sx={{backgroundColor: dividerColor, borderColor: dividerColor, width: "40px", height: "1px"}}/>
          <BasketHeaderStep step={4} currentStep={step} isMobile={store.isMobile}/>
        </>}
      </Box>
    </Box>
  )
}
